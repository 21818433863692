import { MutableRefObject, useRef, useState } from 'react';
import { ColumnsState, ProColumns, TableDropdown } from '@ant-design/pro-components';
import { Site, SiteStatus } from '../services/Api';
import { ControlTwoTone, DeleteTwoTone, KeyOutlined, LockTwoTone, PlusOutlined, UnlockTwoTone } from '@ant-design/icons';
import { Button } from '../components/button/button';
import { Client } from '../services/Client';
import { Input, message, Modal } from 'antd';
import { TableFullHeight } from '../components/table/table-full-height';
import ChangePasswordModalForm from '../components/form/change-password-modal.form';
import { UserButton } from '../components/button/user-button';

export const SitesPage = (props: any) => {
  const tableRef: MutableRefObject<any> = useRef();
  const [editPassword, setEditPassword] = useState<Site>();
  const [columnsStateMap, setColumnsStateMap] = useState<Record<string, ColumnsState>>({});
  const [createSiteFormVisible, setCreateSiteFormVisible] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const [loading, setLoading] = useState<boolean>(false);
  const columns: ProColumns[] = [
    {
      title: 'id',
      //width: 80,
      dataIndex: 'id',
      copyable: true,
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      //width: 50,
      valueType: 'dateTime',
      sorter: true,

    },
    {
      title: 'Описание',
      //width: 150,
      dataIndex: 'title',
    },
    {
      title: 'Субдомен',
      dataIndex: 'slug',
      width:150,
      copyable: true,
      render:(text,record)=>(record.slug)
    },
    {
      title: 'Домены',
      ///width: 150,
      dataIndex: 'domains',
      render: (text, record) => (record.domains.join(', ')),
    },
    {
      title:'Прикрепленный домен',
      width: 150,
      dataIndex:'customDomain'
    },
    {
      title: 'Пользователь',
      //width: 100,
      dataIndex: 'user',
      //@ts-ignore
      valueType:'user',
      render: (_, record) => {
        return <UserButton user={record.user}/>
       // return <>{record.user.first_name} {record.user.last_name}</>;
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      //width: 50,
      valueType: 'checkbox',
      valueEnum: {
        active: { text: 'Активен', status: 'Success' },
        inactive: { text: 'Неактивен', status: 'Default' },
        pending: { text: 'В ожидании', status: 'Processing' },
      },
    },

    {
      key: 'option',
      width: 80,
      fixed: 'right',
      valueType: 'option',
      render: (_: any, record: Site): JSX.Element => {
        const menuItems = [
          { key: 'reset_password', name: 'Сбросить пароль', icon: <KeyOutlined /> },
          { key: 'view_config', name: 'Посмотреть config.php', icon: <ControlTwoTone /> },
          record.status === SiteStatus.Active
            ? { key: 'block', name: 'Заблокировать', icon: <UnlockTwoTone twoToneColor="#ff4d4f" /> }
            : { key: 'unblock', name: 'Разблокировать', icon: <LockTwoTone twoToneColor="#52c41a" /> },
          {key:'delete',name:'Удалить сайт',icon:<DeleteTwoTone twoToneColor={'#ff4d4f'}  />, disabled:!record.deletable}
        ];

        return (
          <TableDropdown
            menus={menuItems}
            onSelect={(key:string) => handleAction(key, record)}
          />
        );
      }
    },
  ];


  /**
   * Единый обработчик действий выпадающего меню.
   *
   * @param {string} key - Ключ выбранного пункта меню.
   * @param {Site} record - Запись сайта, для которой выбрано действие.
   */
  const handleAction = async (key: string, record: Site): Promise<void> => {
    switch (key) {

      case 'reset_password':
        setEditPassword(record);
        break;

      case 'view_config':
        alert('disabled');
        break;

      case 'block':
        modal.confirm({
          title: 'Подтвердите блокировку',
          content: 'Вы действительно хотите заблокировать сайт?',
          okText: 'Да',
          cancelText: 'Отмена',
          onOk: async () => {
            await Client.site.setStatus({ id: record.id, status: SiteStatus.Inactive });
            message.success('Сайт успешно заблокирован');
            tableRef.current.reload();
          },
        });
        break;

      case 'unblock':
        modal.confirm({
          title: 'Подтвердите разблокировку',
          content: 'Вы действительно хотите разблокировать сайт?',
          okText: 'Да',
          cancelText: 'Отмена',
          onOk: async () => {
            await Client.site.setStatus({ id: record.id, status: SiteStatus.Active });
            message.success('Сайт успешно разблокирован');
            tableRef.current.reload();
          },
        });
        break;

        case 'delete':
          let confirmText='';
          modal.confirm({
            title: 'Подтверждение действия',
            content: (
              <div>
                <p>Введите <strong>{record.slug}</strong> для подтверждения:</p>
                <Input
                  placeholder="Введите текст"
                  onChange={(e) => confirmText = e.target.value}
                />
              </div>
            ),
            okText: 'Подтвердить',
            cancelText: 'Отмена',
            async onOk() {
              if (confirmText !== record.slug) {
                modal.error({ title: 'Ошибка', content: 'Текст введен неверно!' });
                return true;
              }
              try {
                setLoading(true)
                await Client.site.delete(record.id);
                message.success('Сайт удален');
                tableRef.current.reload();
              }catch (e) {
                message.error("Ошибка удаления сайта");
              }finally {
                setLoading(false);
              }


            },
          });
          break;

      default:
        break;
    }
  };

  const toolbarRender = () => {
    return [
      <Button
        onClick={async e => {
          const token = prompt('Введите токен medflex');
          if (token) {
            try{
              const result=await Client.medflex.createSession({ token })
              // @ts-ignore
              window.open(result.data['url'], '_blank');

            }catch(err) {
              message.error('Ошибка создания сессии')
            }

          }
        }}
        key="button"
        hint={'Новый сайт'}
        icon={<PlusOutlined/>}
        type="primary"
      />,
    ];
  };

  return <>
    {contextHolder}
    <TableFullHeight
      loading={loading}
      remapFilters={{'user':'userId'}}
      options={{ fullScreen: true }}
      actionRef={tableRef}
      headerTitle={'Сайты'}
      columns={columns}
      rowKey={'id'}
      search={{ filterType: 'light' }}
      request={Client.site.findAll}
      toolBarRender={toolbarRender}
      onRow={(record: Site, rowIndex: number) => {
        return {
          onDoubleClick: () => {

          },
        };
      }}
      columnsState={{
        value: columnsStateMap,
        onChange: setColumnsStateMap,
      }}

      onChange={(pagination:any, filters:any, sorter:any)=>{

      }}
    />

    <ChangePasswordModalForm
      siteId={editPassword?.id||0}
      visible={editPassword!=undefined}
      onClose={()=>{setEditPassword(undefined)}}/>
  </>;
};
