// src/layouts/BasicLayout.tsx
import React from 'react';
import { PageContainer, ProLayout } from '@ant-design/pro-components';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth-provider';
import { Button, Switch } from 'antd';
import { routes } from '../routes';

interface MenuItem {
  path: string;
  name: string;
}

interface BasicLayoutProps {
  darkMode: boolean,
  setDarkMode: (darkMode: boolean) => void,
}

const BasicLayout: React.FC<BasicLayoutProps> = (props:BasicLayoutProps) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const menuItems: MenuItem[] = [
    ...routes,
  ];

  return (
    <ProLayout
      title="CliniLink"
      logo={null}
      menuHeaderRender={(logoDom, titleDom) => {
        return <div style={{ display: 'flex', flex:1, alignItems: 'center', justifyContent:'space-between', padding: '0 16px' }}>
          {logoDom}
          <div style={{ marginRight: 16 }}>{titleDom}</div>
          {/* Переключатель темы справа */}
          <div style={{ marginLeft: 'auto' }} onClick={e=>e.stopPropagation()}>
            <Switch
              checked={props.darkMode}
              onChange={props.setDarkMode}
              checkedChildren="Dark"
              unCheckedChildren="Light"
            />
          </div>
        </div>;
      }}
      style={{ height: '100%' }}
      menu={{
        request: async () => menuItems,

      }}
      pageTitleRender={false}
      onMenuHeaderClick={() => navigate('/dashboard')}
      menuItemRender={(item, dom) => (
        <a onClick={() => navigate(item.path || '')}>{dom}</a>
      )}
      rightContentRender={() => (
        <Button onClick={logout} type="primary">
          Logout
        </Button>
      )}
    >
      <PageContainer style={{ margin: 0, padding: 0 }}>
        <Outlet/>
      </PageContainer>
    </ProLayout>
  );
};

export default BasicLayout;
