import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Button, Input, message, Result, Space } from 'antd';
import { ProForm, ProFormText } from '@ant-design/pro-form';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { Client } from '../../services/Client';
import PhoneInput from 'antd-phone-input';
import { useFormSubmit } from '../../hooks/use-form-submit';
import { AsyncStatus } from '../../common/types';
import StepAnimation from '../step-animation';
import { Site, SiteStatus } from '../../services/Api';
import Intro from './../../assets/images/intro.svg';
import logo from './../../assets/images/clinilink-logo.svg';

interface EmailVerificationFormProps {
  sessionId: string;
  onSuccess: () => void;
}

const EmailVerificationForm = (props: EmailVerificationFormProps) => {
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const createOtpFormRef: MutableRefObject<any> = useRef();
  const [otpCreateRequest, otpCreateStatus] = useFormSubmit(Client.site.sendCode, createOtpFormRef);
  const [start, setStart] = useState(false);

  useEffect(() => {

    if (otpCreateStatus == AsyncStatus.Success) {
      message.success('OTP отправлен на ваш email');
      setOtpSent(true);
    } else if (otpCreateStatus == AsyncStatus.Error) {
      message.error('Ошибка при отправке, проверьте введенные данные');
    }

    setLoading(false);

  }, [otpCreateStatus]);

  // Функция для проверки OTP
  const verifyOtp = async () => {
    try {
      setLoading(true);
      // Замените на URL вашего API для проверки OTP
      const response = await Client.site.verificationCode({ code: otp, sessionId: props.sessionId });
      if (response.status === 200) {
        message.success('Email подтвержден');
        props.onSuccess();
      }
    } catch (error) {
      message.error('Неверный OTP');
    } finally {
      setLoading(false);
    }
  };

  // Проверка на валидность email перед отправкой OTP
  const handleSendOtp = () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      message.error('Пожалуйста, введите корректный email');
      return;
    }
    otpCreateRequest({ email, phone, sessionId: props.sessionId });
  };

  return (
    start ? <>
      <Title level={4}>Проверка Email</Title>

      <Paragraph style={{ fontSize: '15px' }}>


        Для обеспечения безопасности и точности вашей регистрации, мы просим вас подтвердить ваш email-адрес. Мы отправим одноразовый пароль (OTP) на указанный
        вами email. Пожалуйста, проверьте вашу почту (и папку "Спам", если нужно) на наличие OTP, затем введите его ниже для подтверждения вашего email-адреса.

        Этот шаг помогает нам удостовериться, что указанный email действителен и принадлежит вам, чтобы мы могли безопасно отправлять вам важные
        уведомления
      </Paragraph>
      <ProForm
        formRef={createOtpFormRef}
        style={{ width: 300, margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
        onFinish={() => {}}
        submitter={{
          render: (props) => (
            <Space direction="vertical" style={{ width: '100%' }}>
              <Button
                size="large"
                type="primary"
                loading={loading}
                onClick={handleSendOtp}
                disabled={otpSent}
              >
                Отправить код проверки
              </Button>

              {otpSent && (
                <ProForm.Item label={'Введите код полученный в письме'}>
                  <Input.OTP
                    size="large"
                    value={otp}
                    onChange={(value) => {
                      setOtp(value);
                    }}
                  />
                </ProForm.Item>

              )}

              {otpSent && (
                <Button
                  size="large"
                  type="primary"
                  loading={loading}
                  onClick={verifyOtp}
                  disabled={otp.length != 6}
                >
                  Подтвердить
                </Button>
              )}
            </Space>
          ),
        }}
      >


        <Space style={{ marginTop: '16px' }}>
          <ProFormText

            name="email"
            disabled={otpSent}
            label="Введите ваш рабочий Email"
            fieldProps={{
              size: 'large',
              value: email,
              onChange: (e: any) => setEmail(e.target.value),
            }}
            placeholder="Введите ваш email"
            rules={[
              { required: true, message: 'Пожалуйста, введите ваш email' },
              { type: 'email', message: 'Пожалуйста, введите корректный email' },
            ]}
          />

        </Space>

      </ProForm>
    </> :
      <Result
        style={{ padding: '0 32px' }}
        title={<>Добро пожаловать в мир <br/> профессиональных сайтов для клиник</>}
        icon={<><img src={Intro}/></>}
        extra={<Button onClick={e=>setStart(true)} size={'large'} type={'primary'}>Начать активацию</Button>}
      />

  );
};

export default EmailVerificationForm;
