import React from 'react';
import { ProFormText } from '@ant-design/pro-components';
const socialFields = [
  { key: "tg", title: "Telegram" },
  { key: "in", title: "LinkedIn" },
  { key: "yt", title: "YouTube" },
  { key: "fb", title: "Facebook" },
  { key: "dzen", title: "Дзен" },
  { key: "vk", title: "ВКонтакте" },
  { key: "rt", title: "Rutube" },
  { key: "tt", title: "TikTok" },
];



const SocialLinksForm: React.FC = () => {



  return (
    <>
      {socialFields.map(({ key, title }) => (
        <ProFormText
          key={key}
          name={['soc', key]}
          label={title}
          placeholder={`Введите ссылку на ${title}`}
          rules={[{ type: "url", message: `Введите валидный URL для ${title} или оставьте пустым` }]}
        />
      ))}
    </>
  );
};

export default SocialLinksForm;
