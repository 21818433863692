import React, { useEffect, useRef, useState } from 'react';
import {
  ExperimentOutlined,
  SettingOutlined,
  BgColorsOutlined,
  AppstoreOutlined,
  MobileOutlined,
  BuildOutlined,
  CheckSquareOutlined,
  SlidersOutlined,
  DatabaseOutlined,
  ToolOutlined,
  ThunderboltOutlined,
  SearchOutlined,
  LinkOutlined,
  ImportOutlined,
  UserOutlined,
  CalendarOutlined,
  LockOutlined,
  SafetyCertificateOutlined,
  MailOutlined,
  SaveOutlined,
  CloudUploadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import './step-animation.css';
import { Button } from 'antd';
import { Client } from '../services/Client';
import { Site, SiteStatus } from '../services/Api';

const steps = [
  { text: 'Активируем ядро сайта', icon: <ExperimentOutlined/> },
  { text: 'Запускаем процесс генерации', icon: <SettingOutlined/> },
  { text: 'Собираем файлы стилей', icon: <BgColorsOutlined/> },
  { text: 'Активируем шаблон сайта', icon: <AppstoreOutlined/> },
  { text: 'Адаптируем сайт под смартфоны', icon: <MobileOutlined/> },
  { text: 'Расставляем виджеты', icon: <BuildOutlined/> },
  { text: 'Активируем выбранные разделы', icon: <CheckSquareOutlined/> },
  { text: 'Настраиваем активированные разделы', icon: <SlidersOutlined/> },
  { text: 'Создаем демо-данные', icon: <DatabaseOutlined/> },
  { text: 'Применяем основные настройки', icon: <ToolOutlined/> },
  { text: 'Ускоряем ваш сайт', icon: <ThunderboltOutlined/> },
  { text: 'Оптимизируем сайт под поисковые системы', icon: <SearchOutlined/> },
  { text: 'Соединяемся с вашей МИС', icon: <LinkOutlined/> },
  { text: 'Переносим прайс-лист из вашей МИС', icon: <ImportOutlined/> },
  { text: 'Импортируем врачей из вашей МИС на сайт', icon: <UserOutlined/> },
  { text: 'Активируем запись-онлайн к врачам', icon: <CalendarOutlined/> },
  { text: 'Активируем SSL сертификат безопасности', icon: <LockOutlined/> },
  { text: 'Активируем защиту от СПАМа', icon: <SafetyCertificateOutlined/> },
  { text: 'Создаем формы обратной связи', icon: <MailOutlined/> },
  { text: 'Активируем автосохранение страниц', icon: <SaveOutlined/> },
  { text: 'Активируем резервное копирование', icon: <CloudUploadOutlined/> },
  { text: 'Активируем версию для слабовидящих', icon: <EyeOutlined/> },
  { text: 'Проводим тестирование системы', icon: <ExperimentOutlined/> },
  { text: 'Завершаем настройки', icon: <CheckCircleOutlined/> },
  { text: 'Создаем вам аккаунт для администрирования', icon: <UserOutlined/> },
  { text: 'Отправляем логин и пароль вам на почту', icon: <MailOutlined/> },
  { text: 'Запускаем сайт', icon: <RocketOutlined/> },
];

const StepAnimation: React.FC<{site:Pick<Site,'appToken' | 'slug'>}> = ({site}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [siteUrl,setSiteUrl] = useState<string>('');
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    //animation
    if (currentStep < steps.length-1) {
      const timeout = setTimeout(() => {
        setCurrentStep((prev) => prev + 1);
      }, 2000);
      return () => clearTimeout(timeout);
    }

    // check site status for last step
    if (currentStep === steps.length - 1 && !siteUrl) {
      intervalRef.current = setInterval(() => {
        Client.site.getStatus(site.appToken).then((result) => {
          console.log(result)
          if (result.data.status === SiteStatus.Active) {
            clearInterval(intervalRef.current!); //
            setCurrentStep(steps.length);
            setSiteUrl(`https://${site.slug}.clinilink.ru`);
          }
        });
      }, 2000); // Повторять каждые 2 секунды
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Очистка при размонтировании
      }
    };
  }, [currentStep,siteUrl]);



  return (
    <div className={'step-animation'}>
      <div className="container">
        <div className="icon-container">
          {currentStep < steps.length ? (
            <div className="step-icon">{steps[currentStep].icon}</div>
          ) : (
            <CheckCircleOutlined className="complete-icon"/>
          )}
        </div>
        <div className="step-text">
          {currentStep < steps.length ? steps[currentStep].text : 'Процесс завершен!'}
        </div>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${(currentStep / steps.length) * 100}%` }}/>
        </div>
        <div style={{marginTop:40}} className={'buttons'}>

          {siteUrl&&<Button color={'green'} type="primary" size={'large'} href={siteUrl} target="_blank">
            Перейти на сайт
          </Button>}
        </div>
      </div>
    </div>
  );
};

export default StepAnimation;
