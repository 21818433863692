import React, { RefObject, useState } from 'react';
import { Button, Form, Input, message, Steps, Typography } from 'antd';
import ProForm, { ProFormInstance, ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { CheckCircleTwoTone } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { CreateSiteDto, SessionInfoDto, Site } from '../../services/Api';
import { GetLpuGroupResponse } from '../../libs/medflex';
import { Client } from '../../services/Client';
import PhoneInput from 'antd-phone-input';
import SocialLinksForm from './social-links.form';
import StepAnimation from '../step-animation';

const { Step } = Steps;

interface ClinicFormProps {
  site:Site;
  sessionInfo?: SessionInfoDto;
  onSubmit?: (data: CreateSiteDto) => Promise<void>;
  formRef?: RefObject<ProFormInstance>;
  onDomainValidate: (domain: string) => Promise<boolean>;
}

const ClinicForm = (props: ClinicFormProps) => {

  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [selectLpuGroup, setSelectLpuGroup] = useState<GetLpuGroupResponse>();
  const [validDomain, setValidDomain] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);


  const domainTitle = [
    <Typography.Paragraph>Если у вас уже есть собственное доменное имя второго уровня (прим. example.com,sitename.ru) и вы хотите подключить его к сайту,
      сначала измените <b>A-запись</b> домена,
      указав <b>IP-адрес</b> <Typography.Text style={{ fontWeight: 'bold' }} copyable>{props.sessionInfo?.dns[0]}</Typography.Text> Затем введите доменное имя в
      поле ниже и нажмите "Проверить"</Typography.Paragraph>];

  // FORM
  const steps = [
    {
      title: 'Адрес для вашего сайта',
      id: 'selectDomain',
      content: (
        <>
          <ProForm.Item
            name={'slug'}
            rules={[
              { required: true, message: 'Введите адрес для сайта' },
              {
                pattern: /^(?!-)[A-Za-z0-9-]{5,63}(?<!-)$/,
                message: 'Адрес должен быть от 5 до 63 символов латинского алфавита и дефиса, не может начинаться или заканчиваться дефисом',
              },
            ]}
          >
            <Input
              addonBefore="https://"
              suffix=".clinilink.ru"/>
          </ProForm.Item>

          <ProForm.Item
            label={domainTitle}
            name={'domain'}
            rules={[
              {
                pattern: /^[a-zA-Z0-9-_]+\.[a-zA-Z]{2,}$/,
                message: 'Введите корректное имя домена второго уровня!',
              },
              {
                validator: (_, value) => {
                  // Проверяем, что email содержит '@'
                  if (value && !validDomain) {
                    return Promise.reject(new Error('Перед использованием собственного домена, пройдите процедуру проверки A- записи'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Search
              addonBefore={validDomain&&<CheckCircleTwoTone twoToneColor="#52c41a" />}
              onChange={() => {setValidDomain(false);}}
              enterButton={'Проверить'}
              onSearch={async (domain) => {
                if(!domain)return;
                const isValid = await props.onDomainValidate(domain);
                setValidDomain(isValid);
              }}/>
          </ProForm.Item>
        </>
      ),
    },
    {
      title: 'Основная информация',
      content: (
        <>
          <ProFormSelect
            preserve={true}
            name="branchesGroup"
            label="Выберите группу клиник"
            options={props.sessionInfo?.lpuGroups?.map(item => ({ label: item.name, value: item.id }))}

            placeholder="Выберите клинику"
            rules={[{ required: true, message: 'Выберите клинику' }]}
            onChange={(value) => {
              form.setFieldsValue({ branches: undefined });
              if (value) {
                const options = props.sessionInfo?.lpus.map(lpu => lpu.id);
                form.setFieldsValue({ branches: options });
                setSelectLpuGroup(props.sessionInfo?.lpuGroups.find(group => group.id == value));

              }
            }}
          />

          <ProFormSelect
            name={'branches'}
            fieldProps={{ mode: 'multiple' }}
            options={props.sessionInfo?.lpus.filter(lpu => lpu.lpu_group_id == selectLpuGroup?.id).map(lpu => ({ label: lpu.name, value: lpu.id }))}
          />


          <ProFormSelect
            name="clinicProfile"
            label="Профиль клиники"
            options={[
              { label: 'Стоматологическая клиника', value: 'dental' },
              { label: 'Косметологическая клиника', value: 'cosmetic' },
              { label: 'Многопрофильная клиника', value: 'multi' },
              { label: 'Монопрофильная клиника', value: 'mono' },
            ]}
            placeholder="Выберите профиль клиники"
            rules={[{ required: true, message: 'Выберите профиль клиники' }]}
          />


        </>
      ),
    },

    {
      title: 'Информация о клинике',
      content: (
        <>
          <ProFormText
            name="clinicName"
            initialValue={selectLpuGroup?.name}
            label="Название клиники"
            placeholder="Введите название клиники"
            rules={[
              { required: true, message: 'Введите название клиники' },
              {
                validator:(_,value) => {
                  const regex = /^[a-zA-ZА-Яа-я0-9\-,._\s(){}\[\]:;!?@#$%^&*~|]*$/;
                  if (!value || regex.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Некорректный формат. Допустимы только буквы, цифры и некоторые спецсимволы.');
                }
              }
            ]}

          />
          <ProFormTextArea
            fieldProps={{
              showCount: true,
              maxLength: 160,
            }}
            name="clinicDescription"
            label="Описание клиники"
            placeholder="Кратко опишите клинику"
            rules={[
              { required: true, message: 'Опишите клинику', max: 160 },
              {
                validator:(_,value) => {
                  const regex = /^[a-zA-ZА-Яа-я0-9\-,._\s(){}\[\]:;!?@#$%^&*~|]*$/;
                  if (!value || regex.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Некорректный формат. Допустимы только буквы, цифры и некоторые спецсимволы.');
                }
              }
            ]}
          />

          <ProFormTextArea
            name="workingHours"
            label="График работы"
            placeholder="Введите график работы"
            rules={[{ required: true, message: 'Введите график работы' }]}
          />

          <ProFormText
            name="licenseNumber"
            label="Номер лицензии клиники"
            placeholder="Введите номер лицензии"
            rules={[{ required: true, message: 'Введите номер лицензии' }]}
          />
          <ProFormText
            name="inn"
            label="ИНН"
            placeholder="Введите ИНН"
            rules={[{ required: true, message: 'Введите ИНН' }]}
          />

          <ProFormText
          name="location"
          label="Основной адрес клиники"
          rules={[{ required: true, message: 'Введите номер лицензии' }]}
          />

          <ProForm.Item
            name="mainPhone"
            label="Основной контактный телефон клиники"
            rules={[
              { required: true, message: 'Введите основной контактный телефон' },
            ]}
          >
            <PhoneInput/>
          </ProForm.Item>
        </>
      ),
    },

    {
      title: 'Разделы сайта',
      content: (
        <ProFormSelect
          name="sections"
          label="Выберите разделы, которые вам необходимы на сайте"
          options={[
            { label: 'Услуги клиники', value: 'posts' },
            { label: 'Фотоальбом', value: 'albums' },
            { label: 'Новости ', value: 'news' },
            { label: 'Цены', value: 'prices' },
            { label: 'Врачи', value: 'staff' },
            { label: 'Отзывы', value: 'reviews' },
            { label: 'Портфолио', value: 'works' },
            { label: 'Акции клиники', value: 'courses' },
          ]}
          mode="multiple"
          placeholder="Выберите разделы"
          rules={[{ required: true, message: 'Выберите хотя бы один раздел' }]}
        />
      ),
    },
    {
      title:'Социальные сети',
      content:(<SocialLinksForm />)
    },
    {
      title:'Контактные данные',
      content: (
        <>
          <ProForm.Item
            name="phone"
            label="Телефон ответсвенного лица"
            help="Мы можем связаться с вами в случае необходимости уточнения деталей или для более быстрого решения вашего вопроса."
            rules={[
              { required: true, message: 'Введите основной контактный телефон' },
            ]}
          >
            <PhoneInput />
          </ProForm.Item>

          <ProFormText
            name={'firstName'}
            label={"Имя"}
            rules={[
              { required: true, message: 'Введите имя' },
            ]}
          />

          <ProFormText
            name={'lastName'}
            label={"Фамилия"}
            rules={[
              { required: true, message: 'Введите фамилию' },
            ]}
          />
        </>

      )
    }
  ];
  // END FORM

  const next = async () => {
    setLoading(true)
    try {
      //await form.validateFields();




      if (steps[currentStep]?.id == 'selectDomain') {
        const result = await Client.domain.validateSubdomain({ slug: form.getFieldValue('slug') });
        if (result.data == false) {
          form.setFields([
            {
              name: 'slug',
              errors: ['Этот адрес недоступен. Попробуйте выбрать другой.'],
            },
          ]);
          return;
        }
      }else{

        await props.onSubmit?.({ ...data,...form.getFieldsValue(),step:currentStep });
      }

      const errors=props?.formRef?.current?.getFieldsError();

      const hasErrors = errors?.some(field => field.errors.length > 0);
      if(!hasErrors){
        setData({ ...data, ...form.getFieldsValue() });
        setCurrentStep(currentStep + 1);
      }


    } catch(e:any) {
      console.log(e.message);
      message.error('Пожалуйста, исправьте ошибки на этом шаге');
    }finally {
      setLoading(false);
    }

  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinish = async (values: CreateSiteDto) => {

    try{
      const values = await props.formRef?.current?.validateFields();
      await props.onSubmit?.({ ...data, ...values,step:10 });
    }catch (e){}

  };




  return (
    props.site?.id?<StepAnimation site={props.site}/>:<div>
      <Title level={4} style={{ color: '#3b9d9b', fontWeight: 500, marginBottom: 30 }}>
        {steps[currentStep].title}
      </Title>

      <Steps current={currentStep} style={{ marginBottom: 40 }}>
        {steps.map((item, index) => (
          <Step key={item.title}/>
        ))}
      </Steps>
      <ProForm<any>
        loading={loading}
        formRef={props?.formRef}
        form={form}
        onFinish={onFinish}
        initialValues={{}}
        submitter={{
          render: () => (
            <div style={{ marginTop: 24 }}>
              {currentStep < steps.length - 1 && (
                <Button type="primary" onClick={next} loading={loading}>
                  Далее
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button type="primary" htmlType="submit">
                  Завершить
                </Button>
              )}
              {currentStep > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={prev}>
                  Назад
                </Button>
              )}
            </div>
          ),
        }}
      >
        {steps[currentStep].content}
      </ProForm>
    </div>
  );
};

export default ClinicForm;
