import { Button, Layout, message, Result, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ClinicForm from '../components/form/clinic.form';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Client } from '../services/Client';
import { SessionInfoDto, Site } from '../services/Api';
import EmailVerificationForm from '../components/form/email-verification.form';
import { useFormSubmit } from '../hooks/use-form-submit';
import { AsyncStatus } from '../common/types';
import i18n from 'i18next';
import './CreateSitePage.css';
import logo from './../assets/images/clinilink-logo.svg'

export const CreateSitePage = () => {

  const formRef = useRef(null);
  const { sessionId } = useParams();
  const [sessionInfo, setSessionInfo] = useState<SessionInfoDto>();
  const [loading, setLoading] = useState<boolean>(true);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [siteCreate, siteCreateStatus,siteCreateResponse] = useFormSubmit<any>(Client.site.create, formRef);
  const [site, setSite] = useState<Site>();
  const [complete, setComplete] = useState<boolean>(false);

  const getMedflexSession = async (sessionId: string) => {
    try {
      const result = await Client.medflex.getSession(sessionId);
      setSessionInfo(result.data);
      setLoading(false);
    } catch (e) {

      message.error(
        <>
          Ошибка авторизации, обратитесь в службу поддержки
          <p>
            <a href={'mailto://support@clinilink.ru'}>support@clinilink.ru</a>
          </p>

        </>, 0);
    }

  };

  useEffect(() => {

    if (siteCreateStatus == AsyncStatus.Error) {
      setLoading(false);
      message.error('Обнаружена ошибка. Проверьте введенную информацию');
      return;
    }

    if (siteCreateStatus == AsyncStatus.Success && siteCreateResponse?.id) {
      setComplete(true);
    }

  }, [siteCreateStatus]);

  useEffect(() => {
    getMedflexSession(sessionId!);
  }, []);

  const completeMessage = (<Result
    status="success"
    title="Данные успешно отправлены"
    subTitle="Благодарим вас за предоставленные данные для создания сайта. Мы начали процесс, который требует некоторого времени для завершения. Ваш сайт находится в стадии обработки, и как только все будет готово, вы получите уведомление на ваш адрес электронной почты.

Пожалуйста, ожидайте подтверждения по email. В случае возникновения дополнительных вопросов, наша служба поддержки всегда готова помочь вам."
    extra={[
      <Button type="primary" key="support" onClick={() => window.location.href = 'mailto:support@clinilink.ru'}>
        Написать в поддержку
      </Button>
    ]}
  />);

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f7f9fb' }}>


      <Content style={{ padding: '60px 40px' }}>
        <div style={{display:'flex',position:'absolute'}}>
          <img src={logo}/>
        </div>
        <div
          className="clinic-form-container"

        >
          {complete ? completeMessage : <Spin spinning={loading} className={"spinner"} >
            {(emailValid || sessionInfo?.confirmed) ? (
              <ClinicForm
                site={siteCreateResponse?.data}
                formRef={formRef}
                sessionInfo={sessionInfo}
                onSubmit={async data => {
                  data.sessionId = sessionId || '';
                  if(data?.mainPhone){
                    //@ts-ignore
                    data.mainPhone='+' + data.mainPhone.countryCode + data.mainPhone.areaCode + data.mainPhone.phoneNumber
                  }

                  if(data.phone){
                    //@ts-ignore
                    data.phone='+' + data.phone.countryCode + data.phone.areaCode + data.phone.phoneNumber
                  }

                  return siteCreate(data);
                }}
                onDomainValidate={async (domain) => {
                  try {
                    await Client.domain.validateDomain({ domain });
                    message.success('Домен подтвержден');
                    //@ts-ignore
                    formRef.current?.setFields([
                      {
                        name: 'domain',
                        errors: [],
                      },
                    ]);
                    return true;
                  } catch (e: any) {
                    const message = e.response.data.message?.message || e.response.data.message?.domain?.messages?.[0];
                    //@ts-ignore
                    formRef.current?.setFields([
                      {
                        name: 'domain',
                        errors: [i18n.t(`errors.${message}`)],
                      },
                    ]);

                    return false;
                  }
                }}
              />

            ) : <EmailVerificationForm
              sessionId={sessionId!}
              onSuccess={() => setEmailValid(true)}
            />}

          </Spin>}
        </div>
      </Content>
    </Layout>
  );
};
