import { MutableRefObject, RefObject, useCallback, useState } from 'react';
import { AsyncStatus } from '../common/types';
import i18n from 'i18next';
import { ProFormInstance } from '@ant-design/pro-form';

type SubmitFunction = (...args: any) => Promise<any>;

/**
 * Custom hook to handle form submission, manage request status and errors.
 * @param submitAsyncFunction - Async function to handle form submission.
 * @param form - The reference to the Ant Design ProForm instance.
 * @returns A tuple containing the submit function, request status, and response.
 */
export const useFormSubmit = <T, >(
  submitAsyncFunction: (...args: any) => Promise<T>, //
  form: RefObject<ProFormInstance<any>>,
): [(...args: any) => Promise<T | void>, AsyncStatus, T | null] => {
  const [status, setStatus] = useState(AsyncStatus.Idle);
  const [response, setResponse] = useState<T | null>(null);

  const execute = useCallback(async (...args: any) => {
    setStatus(AsyncStatus.Pending);

    // Reset form errors before submission
    const fieldsError = form.current!.getFieldsError();
    const resetFieldsError = fieldsError.map(field => ({ name: field.name[0], errors: [] }));
    form.current!.setFields(resetFieldsError);

    try {
      // Execute the async submit function and handle success
      const result = await submitAsyncFunction(...args);
      setResponse(result);
      setStatus(AsyncStatus.Success);
    } catch (error) {
      // Handle error case
      // @ts-ignore
      const errorResponse = error?.response;

      if (errorResponse?.status === 400 && errorResponse?.data?.message) {
        const _errors = errorResponse.data.message;
        let formErrors: any[] = [];

        if (_errors && typeof _errors === 'object') {
          try {
            formErrors = Object.keys(_errors).map(key => {
              // Translate and set form validation errors
              return {
                name: key,
                errors: [_errors[key]?.rules?.[0] ? i18n.t(`errors.${_errors[key].rules[0]}`) : ''],
              };
            });
            form.current!.setFields(formErrors);
          } catch (errorTranslation) {
            console.error('Error translating form errors:', errorTranslation);
          }
        }
      }

      setStatus(AsyncStatus.Error);
      console.error('Form submission error:', error);
    }
  }, [submitAsyncFunction,form]);

  return [execute, status, response];
};
